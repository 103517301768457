<template>
    <Head :title="meta.title">
        <slot />
    </Head>
</template>
<script setup>
import { Head, usePage } from "@inertiajs/vue3";
const page = usePage();

const meta = page.props.meta;
</script>
